import React from "react"
import { Link } from "gatsby"

import styles from "./secondNav.module.scss"

const SecondNav = props => {
  const onMainClick = evt => {
    evt.preventDefault
    const button = evt.currentTarget
    if (button.hasAttribute("data-listOpen")) {
      button.removeAttribute("data-listOpen")
    } else {
      button.setAttribute("data-listOpen", true)
    }
  }

  return (
    <section className={styles.secondNav}>
      <button className={styles.secondNavTrigger} onClick={onMainClick}>
        {props.children}
      </button>
      <ul className={styles.secondNavList}>
        {props.product && (
          <li>
            <Link to={`/products/${props.product.slug}/`}>
              {props.product.title}
            </Link>
          </li>
        )}
        {props.nav.map(child => (
          <li key={child._key}>
            <Link to={child.url}>{child.title}</Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default SecondNav
